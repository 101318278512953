import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {isEmpty, isLoaded, withFirebase} from 'react-redux-firebase';
import {IReduxState} from "ReduxStrc/IReduxState";
import {LocationDescriptor} from "history";
import withStyles, {WithStyles} from "@material-ui/core/styles/withStyles";
import {styles} from "jss/Layout/User";
import AdminMenu from "Layout/Header/AdminMenu";

export interface IUserProps extends WithStyles<typeof styles> {
    auth?: any;
    firebase?: any;
    dispatch?: any;
}

export interface IUserState {
    anchorEl?: any
}

const to = (to: LocationDescriptor) => ({to, component: Link as any});

export class User extends Component<IUserProps, IUserState> {
    state = {
        anchorEl: null,
    };

    constructor(props) {
        super(props);

        this.handleMenu = this.handleMenu.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleMenu(event) {
        this.setState({anchorEl: event.currentTarget});
    }

    handleClose() {
        this.setState({anchorEl: null});
    }

    renderAuthorised() {
        const {auth, classes} = this.props;
        const {anchorEl} = this.state;
        const open = !!anchorEl;

        return (
            <>
                <Typography
                    className={classes.name}
                    color="inherit"
                >
                    {auth.displayName}
                </Typography>

                <IconButton
                    aria-owns={open ? 'menu-appbar' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleMenu}
                    color="inherit"
                    title={'user'}
                >
                    <AccountCircle/>
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transitionDuration={300}
                    open={open}
                    onClose={this.handleClose}
                >
                    <AdminMenu onClick={this.handleClose}/>
                    <MenuItem {...to("/logout")}
                              onClick={this.handleClose}>Logout</MenuItem>
                    {/*<MenuItem {...to("/profile")}*/}
                    {/*          onClick={this.handleClose}>Profile</MenuItem>*/}
                </Menu>
            </>
        );
    }

    renderUnauthorised() {
        return (
            <Button {...to("/login")} color={'inherit'}>
                Log In
            </Button>
        )
    }

    render() {
        const {auth} = this.props;
        if (!isLoaded(auth)) {
            return false;
        }
        if (isEmpty(auth)) {
            return this.renderUnauthorised();
        }

        return this.renderAuthorised();
    }
}

export default compose(
    connect((state: IReduxState) => ({auth: state.firebase.auth})),
    withFirebase,
    withStyles(styles)
)(User) as React.ComponentType;