import React from 'react';
import {Typography} from "./Typography";
import {withStyles} from '@material-ui/core/styles';
import {styles} from '../jss/Components/Logo';
import {LocationDescriptor} from "history";
import {Link} from "react-router-dom";

interface ILogoProps {
    classes: any;
    onClick?: () => void;
}

const to = (to: LocationDescriptor) => ({to, component: Link as any});

export const Logo: React.FC<ILogoProps> = (props) => {
    const {classes, onClick = () => {}} = props;
    return <Typography className={classes.logo} {...to('/')} onClick={onClick}>
        DANZERY
    </Typography>
};

export default withStyles(styles)(Logo);