import {useMemo} from "react";
import useProfile from "hooks/useProfile";
import {isEmpty, isLoaded} from "react-redux-firebase";

const useClaim = (claim: string) => {
    const profile = useProfile();
    return useMemo(() =>
        !isLoaded(profile) ? null
            : !isEmpty(profile) && !!profile.token.claims[claim], [profile, claim]);
};

export default useClaim;