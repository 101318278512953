import {useEffect, useState} from "react";

const useMobile = () => {
    const [isMobile, setMobile] = useState(false);

    useEffect(() => {
        function defineMobile() {
            setMobile(window.innerWidth <= 500)
        }
        window.addEventListener('resize', defineMobile);
        defineMobile();
    }, []);

    return isMobile;
};

export default useMobile;