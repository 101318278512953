import {Theme} from "@material-ui/core";

export const styles = (theme: Theme) => {
    const gray = '#979797';
    return {
        footer: {
            background: "#000",
            color: "#FFF",
            width: '100%',
            padding: '25px 0',
            bottom: 0,
            display: 'flex',
            justifyContent: 'space-around',
            flexWrap: 'wrap' as 'wrap',
            '@media screen and (max-width: 772px)': {
                flexDirection: 'column' as 'column'
            }
        },
        logo: {
            padding: '0 60px',
            display: 'flex',
        },
        hr: {
            margin: 0,
            border: 0,
            borderLeft: `1px solid ${gray}`,
            '@media screen and (max-width: 772px)': {
                borderBottom: `1px solid ${gray}`,
                margin: '20px 40px'
            },
        },
        content: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column' as 'column',
            alignItems: 'center',
            '& > div': {
                margin: '15px 0',
            }
        },
        menu: {
            width: '80%',
            display: 'flex',
            flexWrap: 'wrap' as 'wrap',
            justifyContent: 'space-around',
            '@media screen and (max-width: 500px)': {
                flexDirection: 'column' as 'column',
                alignItems: 'center',
            },
            '& a': {
                textDecoration: 'none',
                margin: '0 20px',
                '@media screen and (max-width: 950px)':{
                    fontSize: theme.typography.body1.fontSize,
                },
            },
        },
        social: {
            display: 'flex',
            '& svg': {
                width: '100%',
                height: '100%',
                '& path': {
                    transition: '0.3s',
                    fill: gray
                }
            }
        },
        iconWrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 35,
            height: 35,
            transition: '0.4s, border 0.3s',
            borderRadius: '50%',
            padding: '8px',
            margin: '0 10px',
            border: `1px solid ${gray}`,
            '&:hover': {
                padding: 0,
                borderColor: 'transparent',
                '&:nth-child(0) svg path': {
                    fill: '#38A1F3',
                },
                '&:nth-child(1) svg path': {
                    fill: '#4267b2',
                },
                '&:nth-child(2) svg path': {
                    fill: 'url(#grad_insta)',
                },
                '&:nth-child(3) svg path': {
                    fill: '#ED3833',
                },
            }
        },
        copyRight: {
            marginTop: 'auto',
            opacity: 0.43,
            fontWeight: 300,
            fontSize: 12,
        },

    };
};