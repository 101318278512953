import React from 'react';
import {WithStyles, withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {Route} from "react-router-dom";

import Routes from '../routes'
import Header from './Header';
import Cookie from './CookieNotification';
import withRoot from '../withRoot';
import {styles} from 'jss/Layout';
import Footer from "./Footer";
import InstallPWA from "./InstallPWA";

export interface ILayoutProps extends WithStyles<typeof styles>{

}

const Layout: React.FC<ILayoutProps> = (props:ILayoutProps) => {
    const {classes} = props;

    return (
        <Grid container className={classes.root} direction={"column"}>
            <Cookie debug={false && process.env.NODE_ENV === "development"}/>
            <Route component={Header}/>
            <Grid container className={classes.content} direction={"column"}>
                <Routes/>
            </Grid>
            <InstallPWA />
            <Footer/>
        </Grid>
    )
};

export default withRoot(withStyles(styles)(Layout));