import {Theme} from "@material-ui/core";

export const styles = (theme:Theme) => ({
    container: {
        color: '#FFF',
        background: 'rgb(47, 47, 47)',
        display: 'flex',
        flexWrap: 'wrap' as 'wrap',
        alignItems: 'center',
        transition: '0.3s',
        padding: theme.spacing(2),
    },
    close: {
        maxHeight: 0,
        padding: 0,
        opacity: 0,
    },
    actions: {
        display: 'flex',
        marginLeft: 'auto',
    },
    button: {
        marginRight: theme.spacing(1),
    },
});