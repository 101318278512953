import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTypography from '@material-ui/core/Typography';
import {styles} from "jss/Components/Typography";

export function Typography(props: any) {
    return <MaterialTypography {...props} />;
}

let _MainTitle = ({classes, ...props}) => {
    return <Typography component={"h1"} variant={"h2"} className={classes.title} {...props} />
};

let _Title = ({classes, ...props}) => {
    return <Typography variant={"h3"} component={'h2'} className={classes.title} {...props} />
};

export const Title = withStyles(styles)(_Title);
export const MainTitle = withStyles(styles)(_MainTitle);