import {Theme} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";

export const styles = (theme: Theme) => createStyles({
    grow: {
        flexGrow: 1,
    },
    start: {
        marginLeft: '2em',
    },
    button: {
        marginRight: '1em',
    },
    menuButton: {

    },
    hide: {
        display: 'none',
    },
    redDot: {
        width: 8,
        height:8,
        background: theme.palette.error.main,
        borderRadius: '50%',
    }
});