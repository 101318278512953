import React from "react";
import useClaim from "hooks/useClaim";
import to from 'lib/to';
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";

interface IProps {
    onClick: () => void;
}

const AdminMenu: React.FC<IProps> = props => {
    const {onClick} = props;
    const accessAdmin = useClaim('classes_admin');
    const accessTeacher = useClaim('teacher');

    if (!accessAdmin && !accessTeacher) {
        return null;
    }

    return <>
        <MenuItem {...to("/manage/classes")}
                  onClick={onClick}>Manage Classes</MenuItem>
        {accessTeacher &&
        <>
            <MenuItem {...to("/manage/teachers")}
                      onClick={onClick}>Teacher Profile</MenuItem>
            <MenuItem {...to("/manage/payouts")}
                      onClick={onClick}>Payouts</MenuItem>
        </>
        }
        {accessAdmin && <>
            <MenuItem {...to("/manage/teachers")}
                      onClick={onClick}>Manage Teachers</MenuItem>
            <MenuItem {...to("/manage/schools")}
                      onClick={onClick}>Manage Schools</MenuItem>
        </>}
        <Divider/>
    </>
};

export default AdminMenu;