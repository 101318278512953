import React from "react";
// import {ReactComponent as Twitter} from './svg/twitter.svg'
import {ReactComponent as Facebook} from './svg/facebook.svg'
import {ReactComponent as Instagram} from './svg/instagram.svg'
import {ReactComponent as Youtube} from './svg/youtube.svg'

export const Social: React.FC<{ classes: any }> = (props) => {
    const {classes} = props;
    return (
        <div className={classes.social}>
            {/*<a className={classes.iconWrapper} href={'/'}>*/}
            {/*    <Twitter className={classes.twitter}/>*/}
            {/*</a>*/}
            <a className={classes.iconWrapper} href={'https://www.facebook.com/danzery/'} target={'_blank'}
               rel={'noopener noreferrer'}>
                <Facebook className={classes.facebook}/>
            </a>
            <a className={classes.iconWrapper} href={'http://instagram.com/danzery.app/'} target={'_blank'}
               rel={'noopener noreferrer'}>
                <Instagram className={classes.instagram}/>
            </a>
            <a className={classes.iconWrapper} href={'https://www.youtube.com/channel/UCm31Ne3z8JstJ4Rj13j49aQ'}
               rel={'noopener noreferrer'}
               target={'_blank'}>
                <Youtube className={classes.youtube}/>
            </a>
        </div>
    )
};