import {Theme} from "@material-ui/core";

export const styles = (theme: Theme) => {
    return {
        grow: {
            flexGrow: 1,
        },

        //временное решение для логотипа
        noDecoration: {
            textDecoration: 'none'
        },

        header: {
            position: 'sticky' as 'sticky',
            top: 0,
            background: '#FFF',
            color: theme.palette.text.primary,
            transition: '0.3s',
        },

        button: {
            marginRight: '1em',
        },

        transparent: {
            // color: theme.palette.text.primary,
            background: 'transparent',
            color: '#FFF',
            boxShadow: 'none',
        }
    };
};
