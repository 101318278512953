import React from 'react';
import {styles} from 'jss/Layout/Footer';
import Logo from '../../Components/Logo';
import {Social} from "./Social";
import {CopyRight} from "./CopyRight";
import {Menu} from "./Menu";
import withStyles, {WithStyles} from "@material-ui/core/styles/withStyles";

export interface IFooterProps extends WithStyles<typeof styles> {

}

export const Footer: React.FC<IFooterProps> = (props: IFooterProps) => {
    const {classes} = props;

    return (
        <footer className={classes.footer}>
            <div className={classes.logo}>
                <Logo/>
            </div>
            <hr className={classes.hr}/>
            <div className={classes.content}>
                <Social classes={classes}/>
                <Menu classes={classes}/>
                <CopyRight classes={classes}/>
            </div>

        </footer>
    );
};

export default withStyles(styles)(Footer);