import {Theme} from "@material-ui/core";

export const styles = (theme:Theme) => {
    return {
        logo: {
            margin: 'auto',
            fontWeight: 600,
            fontSize: theme.typography.h2.fontSize,
            color: 'inherit',
            textDecoration: 'none',
        },
    }
};