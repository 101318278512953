import Cookie from "cookies-js";

const storeKey = 'store';
export function getCookieStore():Array<any> {
    try {
        let store = Cookie.get(storeKey) || "[]";
        store = JSON.parse(store);
        console.log(store);
        return Array.isArray(store) ? store : [];
    }
    catch (e) {
        console.error(e);
        return [];
    }
}

export function setCookieStore(store) {
    Cookie.set(storeKey, JSON.stringify(store), {expires: 31557600})
}

export function appendCookieStore(collection) {
    let store = getCookieStore();
    store = [...store, collection];
    setCookieStore(store);
}

export function updateCookieStore(object) {
    let store = getCookieStore();
    store = store.map(item => {
        if(item.type === object.type)
            item.value = object.value;

        return item;
    });
    setCookieStore(store);
}

export function clearCookieStore() {
    setCookieStore([]);
}


export function getConsent():boolean {
    return Cookie.get('CookieConsent') as unknown as boolean;
}

export function setConsent(consent:boolean = true){
    Cookie.set('CookieConsent', consent, {expires: 31557600})
}