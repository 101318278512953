import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import {styles} from '../../jss/Layout/CookieNotification';
import {getConsent, setConsent} from "../../lib/Cookie";
import {LocationDescriptor} from "history";
import {Link} from "react-router-dom";
import {Typography} from "Components/Typography";
import cn from 'classnames';

interface ICookieProps {
    debug: boolean;
    classes?: any;
}

interface ICookieState {
    open: boolean
}

const toCheat = (to: LocationDescriptor) => ({to, component: Link as any});

export class CookieNotification extends Component<ICookieProps, ICookieState> {
    static defaultProps = {debug: false};

    constructor(props) {
        super(props);

        let consent: boolean = getConsent() || false;

        this.state = {open: !consent || this.props.debug};
    }

    handleClose = () => {
        //expires in a year
        setConsent();
        this.setState({open: false});
    };

    render() {
        const {classes} = this.props;
        return (
            <div className={cn(classes.container, {[classes.close]: !this.state.open})}>
                <Typography color={'inherit'}>
                    We use cookies to ensure you get the best experience from our website.
                </Typography>
                <div className={classes.actions}>
                    <Button
                        {...toCheat('/policy/cookie')}
                        color="secondary"
                        size="small"
                        className={this.props.classes.button}
                    >
                        Details
                    </Button>
                    <Button
                        className={this.props.classes.button}
                        color="primary"
                        size="small"
                        onClick={this.handleClose}
                    >
                        Accept
                    </Button>
                </div>
            </div>

        );
    }
}

export default withStyles(styles)(CookieNotification);