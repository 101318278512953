import React from "react";
import {Typography} from "Components/Typography";
import {Link} from "react-router-dom";
import {LocationDescriptor} from "history";

export const Menu: React.FC<{ classes: any }> = (props) => {
    const {classes} = props;
    return (
        <div className={classes.menu}>
            <MenuItem to={'/policy/privacy'} isLocal>
                PRIVACY POLICY
            </MenuItem>
            <MenuItem to={'/policy/t&c'} isLocal>
                TERMS OF USE
            </MenuItem>
            <MenuItem to={'mailto:support@danzery.com'}>
                CONTACT US
            </MenuItem>

        </div>
    )
};

interface IMenuItemProps {
    children: React.ReactNode;
    to: string;
    isLocal?: boolean
}
const toCheat = (to: LocationDescriptor) => ({to, component: Link as any});

const MenuItem: React.FC<IMenuItemProps> = ({children, to, isLocal = false}) => {
    if(isLocal) {
        return <Typography
            {...toCheat(to)}
            variant={'subtitle1'}
            color={'inherit'}>
            {children}
        </Typography>
    }

    return <Typography
        component={'a'} href={to}
        variant={'subtitle1'}
        color={'inherit'}>
        {children}
    </Typography>
};