import React from "react";
import Loadable from 'react-loadable';
import {Typography} from "Components/Typography";
import LinearLoader from "Components/LinearLoader";

export default function asyncComponent(importComponent) {
    return Loadable({
        loader: importComponent,
        loading: LoadingComponent
    });
}

const LoadingComponent = ({isLoading, error}) => {
    // Handle the loading state
    if (isLoading) {
        return <LinearLoader/>;
    }
    // Handle the error state
    else if (error) {
        console.log(error);
        return <Typography>Sorry, we have some problems with this page.</Typography>;
    } else {
        return null;
    }
};