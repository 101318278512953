import React, {useCallback, useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import {Theme} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, {WithStyles} from "@material-ui/core/styles/withStyles";
import AddToHomeScreenIcon from '@material-ui/icons/AddToHomeScreen';
import CloseIcon from '@material-ui/icons/Close';

export const styles = (theme: Theme) => createStyles({
    container: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        height: 60,
        zIndex: 100,
        background: theme.palette.text.primary,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '@media only screen and (min-width: 500px)': {
            display: 'none'
        },
        boxShadow: theme.shadows[5],
    },
    button: {
        color: '#FFF',
        borderColor: '#FFF',
        '& svg': {
            marginRight: theme.spacing(1),
        }

    },
    closeIcon: {
        color: "#FFF",
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
    }
});

interface IProps extends WithStyles<typeof styles>{

}

const InstallPWA:React.FC<IProps> = props => {
    const {classes} = props;
    const [event, setEvent] = useState<any | null>(null);

    useEffect(() => {
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            setEvent(e);
        });
    }, []);

    const handleClick = useCallback(() => {
        if(!event) return;
        event.prompt();
        // Wait for the user to respond to the prompt
        event.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
                console.log('User accepted the A2HS prompt');
            } else {
                console.log('User dismissed the A2HS prompt');
            }
            setEvent(null);
        });
    }, [event]);

    if(!event) {
        return null;
    }

    return <div className={classes.container}>
        <CloseIcon className={classes.closeIcon} onClick={() => setEvent(null)}/>
        <Button onClick={handleClick} className={classes.button} variant={"outlined"}>
            <AddToHomeScreenIcon />
            Add to home screen
        </Button>
    </div>
};

export default withStyles(styles)(InstallPWA);