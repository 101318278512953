import {Theme} from "@material-ui/core";

export const styles = (theme:Theme) => {
    return ({
        root: {
            minHeight: '100vh',
            maxWidth: 1920,
            margin: '0 auto',
        },
        content: {
            margin: "0 auto",
            flexGrow: 1,
            flexWrap: 'nowrap' as 'nowrap',
            //maxWidth: "960px",
            '& a': {
                textDecoration: "none",
            }
        }
    });
};