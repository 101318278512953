import React from 'react';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import orange from '@material-ui/core/colors/orange';
import CssBaseline from '@material-ui/core/CssBaseline';

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
    palette: {
        primary: {
            light: blue[300],
            main: blue[500],
            dark: blue[700],
        },
        secondary: {
            light: orange[300],
            main: orange[500],
            dark: orange[700],
        },
    },
    typography: {
        fontFamily: [
            'Helvetica',
            'Arial',
            'sans-serif'
        ].join(','),
        h1: {
            fontSize: 40,
        },
        h2: {
            fontSize: 30,
            fontWeight: 700,
        },
        h3: {
            fontSize: 24,
        },
        subtitle1: {
            fontSize: 19,
        },
        subtitle2: {
            fontSize: 18,
        },
        body1: {
            fontSize: 14,
            fontWeight: 300,
        },
        body2: {
            fontSize: 13,
        },
    },
});

function withRoot(Component) {
    function WithRoot(props) {
        // MuiThemeProvider makes the theme available down the React tree
        // thanks to React context.
        return (
            <MuiThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline/>
                <Component {...props} />
            </MuiThemeProvider>
        );
    }

    return WithRoot;
}

export default withRoot;

/*
73 - 40
50 - 30
33 - 24
28 - 19
24 - 14
 */