import React from 'react'
import {Route, Switch} from 'react-router-dom';
import AsyncComponent from "Components/AsyncComponent";
// import Profile from './Profile';

const AsyncAuth = AsyncComponent(() => import('./Auth'));
const AsyncLogout = AsyncComponent(() => import('./Auth/Logout'));
const AsyncHome = AsyncComponent(() => import('./Home'));
const AsyncPay = AsyncComponent(() => import('./Pay'));
const AsyncTeacher = AsyncComponent(() => import('./Teacher'));
const AsyncSuccessPayment = AsyncComponent(() => import('./Pay/Success'));
const AsyncPolicies = AsyncComponent(() => import('./Policies'));
const AsyncClasses = AsyncComponent(() => import('./Classes'));
const AsyncLive = AsyncComponent(() => import('./Live'));
const AsyncMyClasses = AsyncComponent(() => import('./MyClasses'));
const AsyncManageClasses = AsyncComponent(() => import('./ManageClasses'));
const AsyncTeachersLanding = AsyncComponent(() => import('./Landing/Teachers'));

export const Routes: React.FC = () => {
    return (
        <Switch>
            <Route component={AsyncLogout} path={"/logout"}/>
            <Route component={AsyncAuth} path={"/login"}/>
            <Route component={AsyncPay} path={"/pay/:id?"}/>
            {/*<Route component={Profile} path={"/profile"}/>*/}
            <Route component={AsyncHome} path={"/teacher/fredyclan/lesson/timba"} exact/>
            <Route component={AsyncTeacher} path={'/teacher/:teacherId'}/>
            <Route component={AsyncSuccessPayment} path={'/success/:productId'}/>
            <Route component={AsyncPolicies} path={'/policy'}/>
            <Route component={AsyncClasses} path={'/classes'}/>
            <Route component={AsyncLive} path={'/live/:id?'} />
            <Route component={AsyncMyClasses} path={'/myclasses'} />
            <Route component={AsyncManageClasses} path={'/manage'} />
            <Route component={AsyncTeachersLanding} path={'/for-teachers'} />
            <Route component={AsyncHome}/>
        </Switch>
    )
};

export default Routes