import React from "react";
import {Typography} from "Components/Typography";

export const CopyRight: React.FC<{ classes: any }> = (props) => {
    const {classes} = props;
    return (
        <Typography className={classes.copyRight} variant={'body1'} color={'inherit'}>
            Copyright © 2020 Danzery
        </Typography>
    )
};