import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";

interface IProps {
    zIndex?: number
}

const LinearLoader: React.FC<IProps> = (props) => {
    const {zIndex = 1100} = props;
    return <LinearProgress style={{position: 'fixed', left: 0, right: 0, top: 0, zIndex}}/>
};

export default LinearLoader;