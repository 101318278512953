import React from 'react';
import {combineReducers, compose, createStore} from "redux";
import {firebaseReducer, ReactReduxFirebaseProvider, ReactReduxFirebaseProviderProps,} from "react-redux-firebase";
import firebase from "../firebaseStrc";
import {createFirestoreInstance, firestoreReducer} from "redux-firestore";
import {Provider} from "react-redux";

function onAuthStateChanged(user, firebase, dispatch) {
    // On user login add new listener.
    if (!user)
        return;

    // Check if refresh is required.
    const metadataRef = firebase.firestore().collection('metadata')
        .doc(user.uid); // '/refreshTime'

    const callback = (snapshot) => {
        // Force refresh to pick up the latest custom claims changes.
        // Note this is always triggered on first call. Further optimization could be
        // added to avoid the initial trigger when the token is issued and already contains
        // the latest claims.
        user.getIdToken(true);
    };
    // Subscribe new listener to changes on that node.
    metadataRef.onSnapshot(callback);

}

// react-redux-firebase config
const rrfConfig: any = {
    userProfile: 'users',
    useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
    onAuthStateChanged,
    enableClaims: true,
};

// Add firebase to reducers
const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer // <- needed if using firestore
});

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION__: any;
    }
}
// Create store with reducers and initial state
const initialState = {};
export const store = createStore(
    rootReducer,
    initialState,
// Add reactReduxFirebase enhancer when making store creator
    compose(
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    )
);

const rrfProps: ReactReduxFirebaseProviderProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance // <- needed if using firestore
};

const Redux: React.FC = (props) => {
    return (
        <Provider store={store}>
            <ReactReduxFirebaseProvider {...rrfProps}>
                {props.children}
            </ReactReduxFirebaseProvider>
        </Provider>
    )
};

export default Redux;