import React, {useState} from "react";
import useMobile from "hooks/useMobile";
import IconButton from "@material-ui/core/IconButton";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import MenuIcon from "@material-ui/icons/Menu";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {styles} from "jss/Layout/Menu";
import withStyles, {WithStyles} from "@material-ui/core/styles/withStyles";
import cn from 'classnames';
import to from "lib/to";
import User from "Layout/Header/User";
import Logo from "Components/Logo";
import useProfile from "hooks/useProfile";
import {isEmpty, isLoaded} from "react-redux-firebase";


interface IProps extends WithStyles<typeof styles> {

}

const menuItems = [
    {title: "Live Classes", to: "/classes"},
];

const menuItemsAuth = [
    {title: "My Classes", to: "/myclasses"}
];

const Menu: React.FC<IProps> = props => {
    const profile = useProfile();
    const {classes} = props;
    const isMobile = useMobile();
    const [open, setOpen] = useState(false);

    function isAuth() {
        return isLoaded(profile) && !isEmpty(profile);
    }

    if (!isMobile) {
        return <>
            <Button className={cn(classes.button, classes.start)} {...to('/classes')} color={"inherit"}>
                <div className={classes.redDot} style={{marginRight: 8}}/> Live Classes
            </Button>

            <div className={classes.grow}/>

            {isAuth() && <Button className={classes.button} {...to('/myclasses')} color={"inherit"}>
                My Classes
            </Button>}
            <User/>
        </>;
    }

    return <>
        <div className={classes.grow}/>

        <User/>
        <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            edge="start"
            className={cn(classes.menuButton, open && classes.hide)}
        >
            <MenuIcon/>
        </IconButton>
        <SwipeableDrawer
            anchor={"top"}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
        >
            {renderLists()}
        </SwipeableDrawer>
    </>;

    function renderLists() {
        return <>
            <Logo onClick={() => setOpen(false)} />
            <List>
                {menuItems.map(renderItem)}
            </List>
            <Divider/>

            {isAuth() && <List>
                {menuItemsAuth.map(renderItem)}
            </List>}
        </>
    }

    function renderItem(item, key) {
        const {icon} = item;
        return <ListItem button key={key} {...(to(item.to))} onClick={() => setOpen(false)}>
            {icon && <ListItemIcon>
                {icon}
            </ListItemIcon>}
            <ListItemText primary={item.title}/>
        </ListItem>
    }
};

export default withStyles(styles)(Menu);